<template>
  <div class="applyCompany">
    <div>
      <van-icon name="revoke" @click="$router.push({path:'/project'})" size="26" />
    </div>
    <!-- 申请加入项目satrt -->
    <div
      style="padding: 20px; height: 400px; align-items: start"
      class="flexcolumn"
    >
      <span class="decoration" style="font-size: 14px">申请加入项目</span>
      <van-form @submit="applyBtn">
        <van-field
          required
          label="项目编码"
          input-align="right"
          style="margin: 10px 0"
          @input="changeInput"
          v-model="project.projectcode"
          :rules="[{ required: true, message: '' }]"
        ></van-field>
        <van-field
          style="margin: 10px 0"
          is-link
          :rules="[{ required: true, message: '' }]"
          @click="projectShowDept = true"
          label="选择组织"
          input-align="right"
          readonly
          required
          v-model="deptName"
        ></van-field>
        <van-field
          style="margin: 10px 0"
          input-align="right"
          is-link
          readonly
          :rules="[{ required: true, message: '' }]"
          label="选择职位"
          @click="projectShowPost = true"
          required
          v-model="jobName"
        ></van-field>
        <!-- <van-field
        v-model="project.message"
        label="写入备注"
        rows="3"
        icon-prefix="6"
        label-width="60"
        type="textarea"
        clearable
        placeholder="请输入留言"
        input-align="right"
        show-word-limit
        maxlength="200"
      /> -->
        <van-button
          type="info"
          round
          style="width: 100%; margin-top: 30px"
          native-type="submit"
          >申请</van-button
        >
      </van-form>
    </div>
    <p
      style="font-size: 12px; color: #00b8ff; text-align: center; margin: 10px"
      @click="$router.push({ name: 'applyInfo', params: { type: 2 } })"
    >
      申请记录
    </p>
    <van-popup
      v-model="projectShowDept"
      position="bottom"
      :style="{ height: '30%' }"
    >
      <van-picker
        title="选择组织"
        show-toolbar
        :columns="Deptcolumns"
        @confirm="onConfirmDept"
        value-key="prde03"
        @cancel="projectShowDept = false"
      />
    </van-popup>
    <van-popup
      v-model="projectShowPost"
      position="bottom"
      :style="{ height: '30%' }"
    >
      <van-picker
        title="选择职位"
        show-toolbar
        :columns="Jobcolumns"
        value-key="pror02"
        @confirm="onConfirmJob"
        @cancel="projectShowPost = false"
      />
    </van-popup>
    <!-- 申请加入项目end -->
  </div>
</template>

<script>
import { GetProjectDeptAndJob, ApplyToJoinProject } from "@/api";
export default {
  name: "applyCompany",
  components: {},
  data() {
    return {
      jobName: "",
      deptName: "",
      CompanyValue: "",
      projectShowChildren: false,
      projectShowDept: false,
      projectShowPost: false,
      project: {
        projectcode: "",
        deptid: "",
        jobid: "",
      },
      Jobcolumns: [],
      Deptcolumns: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    onConfirmDept(v) {
      this.project.deptid = v.prde01;
      this.deptName = v.prde03;
      this.projectShowDept = false;
    },
    onConfirmJob(v) {
      this.project.jobid = v.pror01;
      this.jobName = v.pror02;
      this.projectShowPost = false;
    },
    onConfirmProject() {},
    async applyBtn() {
      let res = await ApplyToJoinProject({ ...this.project });
      this.$CodeMessage(res, "申请成功", true);
      this.project.projectcode = "";
      this.project.deptid = "";
      this.project.jobid = "";
      this.deptName = "";
      this.jobName = "";
    },
    async changeInput(v) {
      this.Jobcolumns = [];
      this.Deptcolumns = [];
      this.project.deptid = "";
      this.project.jobid = "";
      this.deptName = "";
      this.jobName = "";
      let res = await GetProjectDeptAndJob({
        params: {
          projectcode: v,
        },
      });
      res.Code == 0
        ? this.$Toast(res.Message)
        : (this.Deptcolumns = res.Data.dept) &&
          (this.Jobcolumns = res.Data.job);
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang='scss' scoped>
.applyCompany {
  padding: 40px;
  height: 100vh;
  width: 100%;
  background-color: rgb(255, 255, 255);
}
</style>